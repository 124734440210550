export default function validate(spotlight) {
  const customLayoutType = spotlight.properties.find(p => p.startsWith('layout_type_'));
  if (customLayoutType === 'layout_type_sidebar') {
    const {
      metadata: {
        custom_layout = {}
      }
    } = spotlight;
    const {
      main_image,
      sidebar_image
    } = custom_layout;
    if (!main_image || !sidebar_image) return {
      error: 'layout_type_sidebar needs to have two images'
    };
  }

  // By default every spotlight is valid
  return true;
}
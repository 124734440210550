import React from 'react';
import SpotlightPlayer from '@upshow/spotlight-player';
import { gql } from '@apollo/client';

export const SpotlightThumbnailFragment = gql`fragment SpotlightThumbnail on Spotlight {
    id properties is_vertical
    TrackingCode { id logo domain tracking_code}
    background { id type url thumbnail }
    text: textRaw
    metadata: metadataRaw
    organization {
        id
        brandKit {
                    logo
                    accent_color
                    background_color
                    hide_poweredbyupshow
                    font_body_color
                    font_body_family
                    font_accent_color
                    font_title_color
                    font_title_family
                }
    }
}`;

export function SpotlightThumbnail ({ spotlight: originalSpotlight, width = 240, display_horizontal = true, no_video = true, ...props }) {
    const spotlight = JSON.parse(JSON.stringify(originalSpotlight));
    const brandkit = spotlight.organization.brandKit;

    if (no_video && ['video', 'youtube'].includes(spotlight.background?.type)) {
        spotlight.background.type = 'image';
        spotlight.background.url = spotlight.background.thumbnail;
    }

    const original_width = spotlight.is_vertical && !display_horizontal ? 1080 : 1920;
    const original_height = spotlight.is_vertical && !display_horizontal ? 1920 : 1080;
    let scale = original_width / width;
    let paddingLeft = 0;
    const height = original_height / scale;

    if (spotlight.is_vertical && display_horizontal) {
        scale = scale * (16 / 9);
        paddingLeft = (width - (original_height / scale)) / 2;
    }

    return <div className="StrippedBackground" style={{ position: 'relative', width, height, paddingLeft }}>
        <SpotlightPlayer showPreview spotlight={spotlight} brandkit={brandkit} style={{ transform: `scale(${1 / scale})` }} {...props}/>
    </div>;
}
import { gql } from '@apollo/client';

export const GET_DATA_FOR_LIVE_EVENT_FORM = gql`query getLiveEventFormInfo {
    Sports {
      display_name
      alias
    }
    Leagues {
      id
      display_name
      alias
    }
    Conferences {
      id
      display_name
      alias
    }
    backend { id
         Users(first: 100, filters: {havingRole: "ROLE_BACKEND_LIVE_EVENT", internal: true, enabled: true}) {
              edges {
                  node {
                      id value: id label: email
                  }
              } 
         }
    }
    subscriptionTypes(activeOnly: true) {
        id
        backwards_compatible_name
        display_name
    }
}`;

export const GET_TEAMS_DATA_FOR_LIVE_EVENT_FORM = gql`query getTeamsLiveEventFormInfo($leagueId: Int) {
    Teams(league_id : $leagueId) {
        display_name
        alias
    }
}`;

export const LIVE_EVENT_FRAGMENT = gql`fragment LiveEventInfo on LiveEvent {
    id
    status
    public
    is_test
    name
    live
    thumbnail
    description
    schedule_end
    schedule_start
    metadata
    subscription_type
    subscriptionType {
        id
        backwards_compatible_name
        display_name
    }
    information
    pay_per_view
    operator {
        id
        username
    }
    automated_start
    automated_end
}`;

const ORGANIZATION_CHECK_FRAGMENT = gql`fragment OrgCheckFragment on Organization {
    leaf is_test is_active
    SalesforceMetadata { metadata }
    dma_code: setting(key: "dma_code") { value}
    has_live_events: has_feature(feature: "live_events")
    has_peacock: has_feature(feature: "peacock")
    has_live_events_multiple_devices: has_feature(feature: "live_events_multiple_devices")
    shouldSignTNFTC: shouldSignTC(subscription_type: "thursday_night_football")
    shouldSignParamountTC: shouldSignTC(subscription_type: "paramount")
    shouldSignVictoryTC: shouldSignTC(subscription_type: "victory")
}`;

export const SUBSCRIPTION_FRAGMENT = gql`fragment LiveEventSubscriptionInformation on LiveEventSubscription {
    id
    Organization {
        id name
        ...OrgCheckFragment
    }
    subscribed_by {
        id
        name
    }
    isLocked
    created_by { id username }
    created_at
    subscribed_devices { id }
}
${ORGANIZATION_CHECK_FRAGMENT}`;

const ALLOWED_ORG_FRAGMENT = gql`fragment LiveEventAllowedOrgFragment on LiveEventAllowedOrg {
    id
    Organization {
        id
        name
        leaf
        ...OrgCheckFragment
    }
    createdAt
}
${ORGANIZATION_CHECK_FRAGMENT}`;

export const GET_LIVE_EVENT = gql`query get_live_event($event_id: Int!) {
    backend { id
        liveEvent(id: $event_id) {
            ...LiveEventInfo
        }
    }
}
${LIVE_EVENT_FRAGMENT}`;

export const CREATE_LIVE_EVENT = gql`mutation CreateLiveEvent ($input: LiveEventInput! ){
    backendMutation {
        createLiveEvent( input: $input ){
            successful
            message
            liveEvent { ...LiveEventInfo }
        }
    }
}
${LIVE_EVENT_FRAGMENT}`;

export const UPDATE_LIVE_EVENT = gql`
    mutation UpdateLiveEvent($event_id: Int!, $input: LiveEventInput!) {
        backendMutation {
            updateLiveEvent(id: $event_id, input: $input) {
                successful
                message
                liveEvent { ...LiveEventInfo }
            }
        }
    }
${LIVE_EVENT_FRAGMENT}`;

export const SET_LIVE_EVENT_DEVICES = gql`
mutation setLiveEventDeviceSubscriptions($organization_id: Int!, $live_event_id: Int!, $devices_id: [Int!]!) {
    backendMutation {
        orgMutation(organization_id: $organization_id){
            setLiveEventDeviceSubscriptions(live_event_id: $live_event_id, devices_id: $devices_id) {
                status
                successful
                message
            }
        }
    }
}`
;

export const SET_LIVE_EVENT_GLOBAL_SUBSCRIPTION = gql`
  mutation setLiveEventSubscriptionGlobal($organization_id: Int!, $live_event_id: Int!) {
    orgMutation(organization_id: $organization_id){
      setLiveEventSubscriptionGlobal(live_event_id: $live_event_id) {
        status
        successful
        message
      }
    }
  }
`;

export const CREATE_LIVE_EVENT_SUBSCRIPTION = gql`
mutation createLiveEventSubscription($org_id: Int!, $eventId: Int!) {
    backendMutation {
        orgMutation (organization_id: $org_id) {
            subscribeLiveEvent(live_event_id: $eventId) {
                successful
                message
            }
        }
    }
}`
;

export const GET_DEVICES = gql`
query getDevices($org_id: Int!) {
    Organization(id: $org_id) {
        id
        devices {
            id
            name
            status
            online
        }
    }
}`
;
export const GET_FULL_LIVE_EVENT = gql`query getLiveEvent($event_id: Int!) {
    backend { id
        liveEvent(id: $event_id) {
            ...LiveEventInfo
            id
            name
            thumbnail
            description
            schedule_end
            schedule_start
            status
            enabled
            metadata
            last_restart
            subscriptionCount
            allowed_orgs {
                ...LiveEventAllowedOrgFragment
            }
            requests {
                id
                requested_at
                Organization {
                    id
                    name
                }
                Requester {
                    id
                    username
                    email
                }
                LiveEvent {
                    id
                }
                requested_at
                fulfilled
                error
                name
                email
                phone_number
                business_name
                business_address
                number_of_locations
                accepted
            }
            created_by {
                username
            }
            created_at
            updated_by {
                username
            }
            updated_at
            subscription_type
            Stream {
                id name health { operational }
            }
        }
    }
}
${ALLOWED_ORG_FRAGMENT}
${LIVE_EVENT_FRAGMENT}`;

export const UPDATE_LIVE_EVENT_STATUS = gql`mutation updateLiveEventStatus($event_id: Int! $status: LiveEventStatus!) {
    backendMutation {
        updateLiveEventStatus(id: $event_id status: $status) {
            successful message
            liveEvent { ...LiveEventInfo }
        }
    }
}
${LIVE_EVENT_FRAGMENT}`;

export const UPDATE_BULK_LIVE_EVENTS_STATUS = gql`mutation updateBulkLiveEventsStatus($event_ids: [Int]! $status: LiveEventStatus!) {
    backendMutation {
        updateBulkLiveEventsStatus(ids: $event_ids status: $status) {
            successful message
            liveEvents { ...LiveEventInfo }
        }
    }
}
${LIVE_EVENT_FRAGMENT}`;

export const GET_LIVE_EVENTS = gql`query get_live_events($from: GraphQLDateTime $is_test: Boolean){
    backend { id
        liveEvents(from: $from is_test: $is_test) {
            ...LiveEventInfo
            subscriptionCount
            Stream { id name health { operational } }
        }
    }
}
${LIVE_EVENT_FRAGMENT}`;

export const RESTART_ORG_DEVICES = gql`mutation restart_org_devices($organization_id: Int!) {
    notify_organization_screens(command: "deponger", organization_id: $organization_id) { 
        successful 
        message
    }
}`;

export const RESTART_ALL_DEVICES = gql`mutation restart_live_events_devices($event_id: Int!) {
    backendMutation {
        restartLiveEventDevices(id: $event_id) {
            successful
            message
        }
    }
}`;

export const ADD_LIVE_EVENT_SUBSCRIPTION = gql`mutation addLiveEventSubscription($organization_id: Int!, $live_event_id: Int! $returnSubscription: Boolean = true) {
    backendMutation {
        subscribeLiveEvent(live_event_id: $live_event_id, organization_id: $organization_id) {
            successful
            message
            liveEventSubscription @include(if: $returnSubscription)  {
                id
                LiveEvent{
                    id
                    subscriptions {
                        ...LiveEventSubscriptionInformation
                    }
                }
            }
        }
    }
}
${SUBSCRIPTION_FRAGMENT}`;

export const GET_SUBSCRIPTIONS = gql`query getSubscriptions($live_event_id: Int!) {
    backend { id
        liveEvent(id: $live_event_id) {
            id subscriptionCount 
            subscriptions {
                ...LiveEventSubscriptionInformation
            }
        }
    }
}
${SUBSCRIPTION_FRAGMENT}`;

export const LIVE_EVENT_ALLOW_ORG = gql`mutation liveEventAllowOrg($organization_id: Int!, $live_event_id: Int! $returnLiveEvent: Boolean = true) {
    backendMutation {
        liveEventAllowOrg(live_event_id: $live_event_id, organization_id: $organization_id) {
            successful
            message
            liveEvent @include(if: $returnLiveEvent) {
                id
                allowed_orgs {
                    ...LiveEventAllowedOrgFragment
                }
            }
        }
    }
}
${ALLOWED_ORG_FRAGMENT}`;

export const LIVE_EVENT_DISALLOW_ORG = gql`mutation liveEventDisallowOrg($id: Int!) {
    backendMutation {
        liveEventDisallowOrg(id: $id) {
            successful
            message
            liveEvent {
                id
                allowed_orgs {
                    ...LiveEventAllowedOrgFragment
                }
            }
        }
    }
}
${ALLOWED_ORG_FRAGMENT}`;

export const REFRESH_ALLOWED_ORGS = gql`query getLiveEventAllowedOrgs($live_event_id: Int!) {
    backend { id
        liveEvent(id: $live_event_id) {
            id allowed_orgs {
                ...LiveEventAllowedOrgFragment
            }
        }
    }
}
${ALLOWED_ORG_FRAGMENT}`;

export const GET_ORGANIZATION_DATA = gql`
    query getOrganizationData($org_id: Int!) {
        Organization(id: $org_id) {
            id
            devices_count
            ...OrgCheckFragment
        }
    }
${ORGANIZATION_CHECK_FRAGMENT}
`;

export const DELETE_LIVE_EVENT = gql`mutation deleteLiveEvent($id: Int!) {
    backendMutation {
        deleteLiveEvent(id: $id) {
            successful message
        }
    }
}`;

export const DELETE_LIVE_EVENT_REQUEST = gql`mutation ($id: Int!){
    backendMutation {
        deleteLiveEventRequest(id: $id) {
            successful
            message
        }
    }
}`;

export const DELETE_LIVE_EVENT_SUBSCRIPTION = gql`mutation deleteLiveEventSubscription($organization_id: Int! $live_event_id: Int!) {
    backendMutation {
        deleteLiveEventSubscription(live_event_id: $live_event_id organization_id: $organization_id) {
            successful
            message
        }
    }
}`;

export const RESTART_DEVICE = gql`mutation sendRestartDevice ($device_ids: [Int!]!) { backendMutation {
    restartDevices(device_ids: $device_ids) { message successful }}   
}`;

export const GET_SPORT_LEAGUE_GENDERS = gql`
query LeagueGenders {
    backend{
        LeagueGenders {
            id
            display_name
            sport{
                id
                alias
            }
            league{
                id
                alias
            }
            gender
        }
    }
}`;

export const GET_TEAM_BY_ID = gql`query GetTeamAndRelatedLeagues($id: Int!) {
    Teams(id: $id) {
      id
      display_name
      background_color
      background_image
      logo
      alias
      state
      city
      leagues {
        id
        display_name
        alias
        sports {
          id
          display_name
        }
      }
      leagueGenders{
        id
        sport{
          id
          alias
        }
        league{
          id
          alias
        }
        gender
      }
    }
  }`;

import { useMemo } from 'react';
import { DateTime } from 'luxon';
const DEFAULT_PALETTE_COLOR = '#000';
const HIDE_URL_CAMPAIGN_TYPES = ['EMPLOYEE_BIO', 'EMPLOYEE_SHOUTOUT'];
export const isDefined = value => !(value === undefined || value === null);
export const titleFontSizes = {
  small: 212.5 * 2.13,
  medium: 262.5 * 2.13,
  large: 362.5 * 2.13,
  xlarge: 500 * 2.13
};
export const bodyFontSizes = {
  small: 125 * 2.13,
  medium: 175 * 2.13,
  large: 225 * 2.13,
  xlarge: 240 * 2.13
};
export const getSpotlightStyles = (metadata, text) => {
  var _text$title_color, _metadata$accent_colo;
  const title_color = (_text$title_color = text.title_color) !== null && _text$title_color !== void 0 ? _text$title_color : DEFAULT_PALETTE_COLOR;
  const accent_color = (_metadata$accent_colo = metadata.accent_color) !== null && _metadata$accent_colo !== void 0 ? _metadata$accent_colo : DEFAULT_PALETTE_COLOR;
  const text_accent_color = text.accent_color;
  const title_font_family = text.title_font_family;
  const body_color = text.body_color;
  const body_font_family = text.body_font_family;
  const titleStyle = {
    color: text.title_color,
    fontFamily: text.title_font_family,
    fontSize: titleFontSizes.medium
  };
  const descriptionStyle = {
    color: text.body_color
  };
  const containerStyle = {
    color: text.body_color,
    fontFamily: text.body_font_family,
    fontSize: bodyFontSizes.medium
  };
  const listHeaderRowStyle = {
    backgroundColor: accent_color,
    color: text.accent_color,
    fontFamily: text.title_font_family
  };
  return {
    title_color,
    accent_color,
    title_font_family,
    body_color,
    body_font_family,
    text_accent_color,
    titleStyle,
    descriptionStyle,
    containerStyle,
    listHeaderRowStyle
  };
};
export const paginateArray = (array, chunkSize = 10) => {
  const paginatedArray = [];
  if (chunkSize <= 0) return array;
  for (let i = 0, len = array.length; i < len; i += chunkSize) {
    paginatedArray.push(array.slice(i, i + chunkSize));
  }
  return paginatedArray;
};
export const sortByPropAlphabetically = (array, prop) => {
  return array.sort((a, b) => {
    var _a$prop$toLowerCase, _a$prop, _b$prop$toLowerCase, _b$prop;
    // If a value does not have prop will be listed at the bottom

    const aValue = (_a$prop$toLowerCase = (_a$prop = a[prop]) === null || _a$prop === void 0 ? void 0 : _a$prop.toLowerCase()) !== null && _a$prop$toLowerCase !== void 0 ? _a$prop$toLowerCase : 'z';
    const bValue = (_b$prop$toLowerCase = (_b$prop = b[prop]) === null || _b$prop === void 0 ? void 0 : _b$prop.toLowerCase()) !== null && _b$prop$toLowerCase !== void 0 ? _b$prop$toLowerCase : 'z';
    if (aValue < bValue) {
      return -1;
    }
    if (aValue > bValue) {
      return 1;
    }
    return 0;
  });
};
export const formatMilitaryToRegular = time => {
  if (isNaN(time)) return '-';
  return DateTime.fromFormat(time.toString(), 'Hmm').toFormat('hh:mm a');
};
export const chunk = (input, size) => {
  return input.reduce((arr, item, idx) => {
    return idx % size === 0 ? [...arr, [item]] : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
  }, []);
};
export const formatLastUpdated = (page, timezone) => {
  var _page$map$filter$reve;
  return (_page$map$filter$reve = page.map(loc => DateTime.fromISO(loc.last_updated, {
    zone: timezone
  })).filter(date => !date.invalid).reverse()[0]) === null || _page$map$filter$reve === void 0 ? void 0 : _page$map$filter$reve.setLocale('en-US');
};
export function useLastUpdated(page, timezone) {
  return useMemo(() => formatLastUpdated(page, timezone), [page, timezone]);
}
export const formatDollar = (value, fractionDigits = 0) => {
  const format_options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  };
  return new Intl.NumberFormat('en-US', format_options).format(value);
};
export const getSpecificDateInMillis = (date, hour, zone) => {
  return DateTime.fromISO(`${date}T${hour}`, {
    zone
  }).toMillis();
};
export const getCurrentDateInMillis = timezone => {
  return DateTime.local().setZone(timezone).toMillis();
};
export const getValidSpecificDate = (date, hour, zone) => {
  if (!date || !hour) return 'N/A';
  return DateTime.fromISO(`${date}T${hour}`, {
    zone
  }).toFormat('MM/dd/yy');
};
export const getValidSpecificLoyaltyDate = (date, zone) => {
  if (!date) return 'N/A';
  return DateTime.fromISO(`${date}`, {
    zone
  }).toFormat('MM/dd/yy');
};
export const getRecurringDescription = (text, last_date_formatted) => {
  return `This week's ${text} leaders as of ${last_date_formatted}`;
};
export const getDescription = (timezone, text) => {
  const currentDate = DateTime.local().setZone(timezone);
  return `${capitalizeString(text)} ${currentDate.toFormat('MM/dd/yyyy')}`;
};
export const getLoyaltyDescription = timezone => {
  const currentDate = DateTime.local().setZone(timezone);
  return `Loyalty ${currentDate.toFormat('MM/dd/yyyy')}`;
};
export const roundValueToTwoDecimals = value => {
  if (value) return value.toFixed(2);
};
export const isValidString = str => typeof str === 'string' && !!str.trim().length;
export const capitalizeString = str => str.charAt(0).toUpperCase() + str.slice(1);
export const showQRShortURL = campaign_type => !HIDE_URL_CAMPAIGN_TYPES.includes(campaign_type);
export function replaceDoubleQuotes(element) {
    if (typeof element === 'object' && element !== null) {
        for (const key in element) {
            if (element.hasOwnProperty(key)) {
                if (typeof element[key] === 'string') {
                    element[key] = element[key].replace(/"/g, "'");
                }
            }
        }
    }
    return element;
}